import { type Ref, forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import { useColorScheme } from "@remhealth/ui";

interface PadContainerProps {
  $width: number;
  $height: number;
}

const PadContainer = styled.div<PadContainerProps>`
  padding: 0;
  background-color: ${props => props.theme.background.default};
  border: 1px solid ${props => props.theme.border.default};
  border-radius: var(--pt-border-radius);
  overflow: hidden;
  line-height: 0;
  /** Reverse the zoom from Large Font, because droppable area gets shifted, BELLS-5968 */
  zoom: calc(1 / var(--zoom, 1));
  width: calc(${props => props.$width}px * var(--zoom, 1));
  height: calc(${props => props.$height}px * var(--zoom, 1));
`;

export interface SignatureCanvasProps {
  width: number;
  height: number;
  onEnd?: () => void;
}

export interface SignatureCanvasHandle {
  isEmpty(): boolean;
  getData(type: "image/png" | "image/jpeg", quality?: number): string;
}

export const SignatureCanvas = forwardRef((props: SignatureCanvasProps, ref: Ref<SignatureCanvasHandle>) => {
  const { width, height, onEnd } = props;

  const { zoom } = useColorScheme();

  const signatureSize = useMemo(() => ({
    width: width * zoom,
    height: height * zoom,
  }), [width, height, zoom]);

  const canvasRef = useRef<ReactSignatureCanvas>(null);

  useImperativeHandle(ref, () => ({
    isEmpty,
    getData,
  }), []);

  return (
    <PadContainer $height={height} $width={width}>
      <ReactSignatureCanvas ref={canvasRef} canvasProps={signatureSize} penColor="black" onEnd={onEnd} />
    </PadContainer>
  );

  function isEmpty(): boolean {
    return canvasRef.current?.isEmpty() ?? true;
  }

  function getData(type: string, quality?: number): string {
    if (!canvasRef.current) {
      throw new Error("Canvas is not attached");
    }
    const canvas = canvasRef.current.getCanvas();
    return canvas.toDataURL(type, quality);
  }
});
