import * as prod from "react/jsx-runtime";
import { type PluggableList, unified } from "unified";
import rehype from "rehype-dom-parse";
import rehypeToHtml, { Options as RehypeStringifyOptions } from "rehype-stringify";
import rehypeToReact from "rehype-react";
import { TextifyOptions, rehypeTextify, textify } from "./textify";
import { reactSanitize, sanitize } from "./sanitize";
import { prettify } from "./prettify";

/**
 * Careful!  These settings currently must match the format that Quill reports
 * in order to avoid false positives of the form.dirty state.
 */
const rehypeToStringOptions: RehypeStringifyOptions = {
  collapseEmptyAttributes: true,
  allowDangerousCharacters: true,
  characterReferences: {
    useNamedReferences: true,
  },
};

export interface HtmlToStringOptions {
  plugins: PluggableList;
}

export const htmlToString = (html: string, options: HtmlToStringOptions) => {
  const transformer = unified()
    .use(rehype, { fragment: true })
    .use(options.plugins ?? [])
    .use(rehypeToHtml, rehypeToStringOptions);

  return transformer.processSync(html).toString();
};

export interface HtmlToTextOptions extends TextifyOptions {
  plugins?: PluggableList;
}

export const htmlToText = (html: string, options?: HtmlToTextOptions) => {
  const transformer = unified()
    .use(rehype, { fragment: true })
    .use(options?.plugins ?? [])
    .use(sanitize)
    .use(textify, options)
    .use(rehypeTextify);

  return transformer.processSync(html).toString();
};

export interface EditorToHtmlOptions {
  prettify?: boolean;
}

export const editorToHtml = (editorHtml: string, options?: EditorToHtmlOptions) => {
  return htmlToString(editorHtml, {
    plugins: [
      [sanitize],
      [prettify, { enabled: options?.prettify ?? false }],
    ],
  });
};

export const escapeHtmlString = (text: string): string => {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

export interface MarkupToReactOptions {
  plugins?: PluggableList;
}

interface RehypeToReactOptions {
  jsx: Jsx;
  jsxs: Jsx;
  Fragment: unknown;
}

type Jsx = (type: any, props: any, key?: string | undefined) => JSX.Element;
const production: RehypeToReactOptions = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };

export const htmlToReact = (html: string, options?: MarkupToReactOptions) => {
  return unified()
    .use(rehype, { fragment: true })
    .use(reactSanitize)
    .use(options?.plugins ?? [])
    .use(rehypeToReact, {
      ...production,
    })
    .processSync(html)
    .result;
};
