import { CodeableConcept, Coding, type Signature, systems } from "@remhealth/apollo";
import { GenderIdentityFilter, type Labeling } from "@remhealth/core";
import type { CompletedSignature, DraftSignature } from "~/notes/types";

interface GenderCode {
  system?: string;
  code: string;
}

export interface GenderIdentity {
  label: string;
  values: GenderCode[];
}

export const maleGenderCodes: GenderCode[] = [
  { code: "male" },
  { system: systems.oids.genderIdentity, code: "446151000124109" },
  { system: systems.oids.snomed, code: "446151000124109" },
];

export const femaleGenderCodes: GenderCode[] = [
  { code: "female" },
  { system: systems.oids.genderIdentity, code: "446141000124107" },
  { system: systems.oids.snomed, code: "446141000124107" },
];

export function isGenderCode(coding: Coding, genderCode: GenderCode): boolean {
  if (genderCode.system) {
    return coding.system.toLowerCase() === genderCode.system.toLowerCase() && coding.code.toLowerCase() === genderCode.code.toLowerCase();
  }
  return coding.code.toLowerCase() === genderCode.code.toLowerCase();
}

export function genderIdentitiesToFilter(genderIdentities: GenderIdentity[]): GenderIdentityFilter[] {
  const filters: GenderIdentityFilter[] = [];
  const identityCodings = genderIdentities.flatMap(i => i.values);

  identityCodings.forEach(coding => {
    const existing = filters.find(f => f.system === coding.system);
    if (existing) {
      existing.anyCodes.push(coding.code);
    } else {
      filters.push({ system: coding.system, anyCodes: [coding.code] });
    }
  });

  return filters;
}

export const genderFilterCodings = {
  male: { system: systems.genderIdentity, code: "male" },
  female: { system: systems.genderIdentity, code: "female" },
} satisfies Record<string, Coding>;

export function isGenderIdentity(genderIdentity: CodeableConcept, value: "male" | "female") {
  return genderIdentity.codings.some(identityCode => {
    return (value === "male" ? maleGenderCodes : femaleGenderCodes).some(genderCode => isGenderCode(identityCode, genderCode));
  });
}

export function getSignerName(signature: CompletedSignature | DraftSignature | Signature, labels: Labeling, includeRole: boolean): string {
  const displayParts = [];

  if (signature.signer?.display) {
    displayParts.push(signature.signer?.display);
  }

  if (includeRole) {
    if (signature.signer?.resourceType === "Patient") {
      displayParts.push(`(${labels.Patient})`);
    } else if (displayParts.length > 0 && signature.role?.text) {
      displayParts.push(`(${signature.role?.text})`);
    }
  }

  if (displayParts.length === 0 && "customLabel" in signature && signature.customLabel) {
    const label = signature.customLabel;
    displayParts.push(label);
  }

  if (displayParts.length === 0 && "type" in signature) {
    switch (signature.type) {
      case "patient":
        displayParts.push(`${labels.Patient} Signature`);
        break;
      case "guardian":
        displayParts.push("Guardian Signature");
        break;
      case "other":
      case "adhoc":
        displayParts.push("Other Signature");
        break;
    }
  }

  return displayParts.join(" ");
}
